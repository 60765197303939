import { Analytics } from "@vercel/analytics/react";

import DesktopImg from "../src/images/avdesktop1.png";
import MobImg from "../src/images/avmobile1.png";

import "./App.css";

function App() {
  return (
    <>
      <section>
        <div>
          <a to="mailto:sondeshwar@icloud.com">
            <img className="desktop" src={DesktopImg} alt="av ai" />
            <img className="mobile" src={MobImg} alt="av ai" />
          </a>
        </div>
      </section>
      <Analytics />
    </>
  );
}

export default App;
